<template>
  <div>
    <b-row>
      <b-col lg="6" />
      <b-col
        class="mb-1"
        lg="6"
      >
        <!-- button on right -->
        <form @submit.prevent="search_pickup">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                v-b-modal.modal-prevent-closing
                variant="outline-primary"
              >
                Filters
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="form.q"
              autofocus
              placeholder="Search by pickup no. or client information"
              required="required"
            />
            <b-input-group-append>
              <b-button
                type="submit"
                variant="primary"
              >
                search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>
        <b-modal
          id="modal-prevent-closing"
          ref="my-modal"
          cancel-variant="outline-secondary"
          ok-title="Filter"
          size="lg"
          title="Filter pickups"
          @ok="handleOk"
        >
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <b-row>
              <b-col md="6">
                <b-form-group
                  invalid-feedback="search by Pickup No"
                  label="By Pickup No"
                  label-for="pickup_no"
                >
                  <b-form-input
                    id="awb"
                    v-model="form.pickup_no"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="By Client"
                  label-for="client"
                >
                  <v-select
                    id="client"
                    v-model="form.client_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :filterable="false"
                    :options="clients"
                    label="name"
                    placeholder="Search by client no, name, contact name, phone or mobile..."
                    @search="searchClient"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <h5>Range</h5>
                  <flat-pickr
                    v-model="form.rangeDate"
                    :config="{ mode: 'range'}"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="By Status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="form.status_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statues"
                    :reduce="status => `${status.id}`"
                    label="name"
                    placeholder="Search by client no, name, contact name, phone or mobile..."
                  >
                    <!--                                        <template #option="{ name, code }">
                                                                <span> {{ code }}</span> -
                                                                <span> {{ name }}</span>
                                                            </template>-->
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loading"
          rounded="sm"
        >
          <b-card-code title="pickups">

            <div class="float-left">
              <b-button
                type="button"
                variant="success"
                @click.prevent="$router.push('/pickup/create')"
              >
                Add new pickup
              </b-button>
              <!--              <b-button
                v-if="selected"
                v-b-modal.modal-status
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-warning"
              >
                Actions
              </b-button>-->
            </div>

            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    class="d-inline-block"
                    placeholder="Search"
                    type="text"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              @on-select-all="onSelectAll"
              @on-selected-rows-change="selectionChanged"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: client -->
                <span
                  v-if="props.column.field === 'client_id'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.client ? props.row.client.name : '' }}</span>
                </span>
                <!-- Column: client -->
                <span
                  v-else-if="props.column.field === 'client.phone'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.client ? props.row.client.phone : '' }}</span>
                </span>
                <!-- Column: client -->
                <span
                  v-else-if="props.column.field === 'client.address'"
                  class="text-nowrap"
                >
                  <span
                    v-if="props.row.client.addresses.length !== 0"
                    class="text-nowrap"
                  >{{
                    props.row.client ?
                      (props.row.client.addresses ? props.row.client.addresses[0].address : '')
                      : ''
                  }}</span>
                </span>
                <!-- Column: Status -->
                <span
                  v-else-if="props.column.field === 'status_id'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.status ? props.row.status.name : '' }}</span>
                </span>

                <!-- Column: Date -->
                <span
                  v-else-if="props.column.field === 'due_date'"
                  class="text-nowrap"
                >
                  <span
                    v-if="props.row.time"
                    class="text-nowrap"
                  >{{
                    props.row.due_date + ' ' + props.row.time | formatDate
                  }}</span>

                  <span
                    v-else
                    class="text-nowrap"
                  >{{
                    props.row.due_date | formatDateOnly
                  }}</span>
                </span>

                <!-- Column: Created At -->
                <span
                  v-else-if="props.column.field === 'created_at'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{
                    props.row.created_at | formatDate
                  }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      no-caret
                      toggle-class="text-decoration-none"
                      variant="link"
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          class="text-body align-middle mr-25"
                          icon="MoreVerticalIcon"
                          size="16"
                        />
                      </template>
                      <b-dropdown-item :to="`pickup/${props.row.id}/show`">
                        <feather-icon
                          class="mr-50"
                          icon="EyeIcon"
                        />
                        <span>Show</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.status_id !== pickup_status"
                        :to="`shipment/create?pickup_id=${props.row.id}`"
                      >
                        <feather-icon
                          class="mr-50"
                          icon="PlusCircleIcon"
                        />
                        <span>Add new Shipment</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.status_id !== pickup_status"
                        :to="`shipment/uploadFile?pickup_id=${props.row.id}`"
                      >
                        <feather-icon
                          class="mr-50"
                          icon="UploadCloudIcon"
                        />
                        <span>Upload File</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.status_id !== pickup_status"
                        :to="`pickup/${props.row.id}/edit`"
                      >
                        <feather-icon
                          class="mr-50"
                          icon="Edit2Icon"
                        />
                        <span>Update</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.status_id !== pickup_status"
                        @click="cancelItem(props.row, props.index)"
                      >
                        <feather-icon
                          class="mr-50"
                          icon="TrashIcon"
                        />
                        <span>Cancel</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '50', '100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>

          </b-card-code>
        </b-overlay>
        <b-modal
          id="modal-status"
          ref="status-modal"
          cancel-variant="outline-secondary"
          ok-title="Save"
          title="Change Status"
          no-close-on-backdrop
          @ok="changeStatus"
        >
          <b-form-group
            label="change status"
            label-for="status_id"
          >
            <v-select
              id="status_id"
              v-model="multi_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statues"
              :reduce="status => status.id"
              label="name"
            />
          </b-form-group>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { debounce } from 'lodash'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import axios from '@/libs/axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BOverlay,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Status',
          field: 'status_id',
        },
        {
          label: 'Pickup',
          field: 'pickup_no',
        },
        {
          label: 'Client',
          field: 'client_id',
        },
        {
          label: 'Due Date',
          field: 'due_date',
        },
        {
          label: 'Packages',
          field: 'packages_count',
        },
        {
          label: 'Notes',
          field: 'notes',
        },
        {
          label: 'Client Phone',
          field: 'client.phone',
        },
        {
          label: 'Client Address',
          field: 'client.address',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      clients: [],
      statues: [],
      form: {
        q: null,
        pickup_no: null,
        client_id: null,
        rangeDate: null,
        status_id: null,
      },
      loading: false,
      selected: false,
      selected_rows: [],
      multi_status: null,
      pickup_status: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.form.status_id = JSON.parse(localStorage.getItem('settings')).placed_status
    this.filter_pickup()
    this.getStatues()
    this.pickup_status = JSON.parse(localStorage.getItem('settings')).done_status
  },
  methods: {
    cancelItem(item, index) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to cancel this pickup.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(confirmed => {
          if (confirmed) {
            axios.post(`/pickups/${item.id}/cancel`, { _method: 'PUT' }).then(resp => {
              if (resp.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Pickup has been canceled successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.rows.splice(index, 1)
              }
            })
          }
        })
    },
    searchClient(search, loading) {
      if (search) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.post('/clients/search', {
        /* filters: [
              {field: 'client_no', 'operator': '=', value: search}
          ], */
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.clients = res.data.data
        loading(false)
      })
    }, 350),
    search_pickup() {
      this.loading = true
      axios.post('/pickups/search', {
        search: { value: this.form.q },
      }).then(res => {
        this.rows = res.data.data
        this.loading = false
      })
    },
    filter_pickup() {
      const filtersArr = []
      if (this.form.pickup_no) {
        filtersArr.push({ field: 'pickup_no', operator: '=', value: this.form.pickup_no })
      }

      if (this.form.client_id) {
        filtersArr.push({ field: 'client_id', operator: '=', value: this.form.client_id.id })
      }
      if (this.form.rangeDate) {
        const rangeDates = this.form.rangeDate.split(' to ')
        filtersArr.push(
          { field: 'created_at', operator: '>=', value: rangeDates[0] },
          { field: 'created_at', operator: '<', value: rangeDates[1] },
        )
      }
      if (this.form.status_id) {
        filtersArr.push({ field: 'status_id', operator: '=', value: this.form.status_id })
      }
      if (filtersArr.length !== 0) {
        this.loading = true
        axios.post('/pickups/search', {
          filters: filtersArr,
          // search: {"value": this.form.q}
        }).then(res => {
          this.rows = res.data.data
          this.loading = false
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      /* if (!this.checkFormValidity()) {
          return
      } */
      // Push the name to submitted names
      this.filter_pickup()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    onSelectAll(params) {
      this.selected = params.selected
      this.selected_rows = params.selectedRows
    },
    selectionChanged(params) {
      this.selected_rows = params.selectedRows
      this.selected = this.selected_rows.length !== 0
    },
    getStatues() {
      axios.get('/pickupStatuses/').then(resp => {
        this.statues = resp.data.data
      })
    },
    changeStatus(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      const pickupsArr = []
      this.selected_rows.map(item => pickupsArr.push(item.id))
      // console.log(pickupsArr)
      // Trigger submit handler
      axios.post('/pickups/changeStatus', {
        pickups: pickupsArr,
        status: this.multi_status,
      }).then(res => {
        if (res) {
          this.selected_rows = []
          this.selected = false
          this.multi_status = null
          this.$nextTick(() => {
            this.$refs['status-modal'].toggle('#toggle-btn')
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'status has been changed successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.filter_pickup()
        }
        this.loading = false
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
@import 'src/@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  min-height: 219px;
}
</style>
